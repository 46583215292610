import React, { useEffect, useRef } from "react";

function generateNormalDistributionData(min, max, targetValue, numPoints) {
  const mean = (min + max) / 2;
  const stdDev = (max - min) / 6; // Assuming 99.7% of values fall within 3 standard deviations

  const data = [];
  const increment = (max - min) / numPoints;

  for (let x = min; x <= max; x += increment) {
    const y =
      (1 / (stdDev * Math.sqrt(2 * Math.PI))) *
      Math.exp(-((x - mean) ** 2) / (2 * stdDev ** 2));
    data.push({ x, y });
  }

  return data;
}

const NormalDistributionChart = ({ targetValue }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const canvas = chartRef.current;
    const ctx = canvas.getContext("2d");
    const chartWidth = canvas.width;
    const chartHeight = canvas.height;
    const data = generateNormalDistributionData(
      40,
      62,
      targetValue,
      chartWidth
    );

    // Find the maximum y value in the chart data
    const maxY = Math.max(...data.map((point) => point.y));

    // Calculate the scaling factors for the chart
    const scaleX = chartWidth / (62 - 40);
    const scaleY = chartHeight / maxY;

    // Clear the canvas
    ctx.clearRect(0, 0, chartWidth, chartHeight);

    // Draw the dots
    ctx.fillStyle = "#F48D7E";
    data.forEach((point) => {
      const x = (point.x - 40) * scaleX;
      const y = chartHeight - point.y * scaleY;
      ctx.fillRect(x - 1, y - 1, 2, 2); // Draw a small rectangle at each data point location
    });

    // Draw the chart curve
    ctx.beginPath();
    ctx.moveTo(0, chartHeight);

    data.forEach((point, index) => {
      const x = (point.x - 40) * scaleX;
      const y = chartHeight - point.y * scaleY;

      ctx.lineTo(x, y);

      if (point.x >= targetValue) {
        ctx.lineTo(x, chartHeight);
      }
    });

    ctx.fillStyle = "#F48D7E";
    ctx.closePath();
    ctx.fill();
    ctx.stroke();

    // Create a clipping region for the remaining area beyond the target value
    ctx.beginPath();
    ctx.moveTo((targetValue - 40) * scaleX, chartHeight);
    data.forEach((point) => {
      if (point.x >= targetValue) {
        const x = (point.x - 40) * scaleX;
        const y = chartHeight - point.y * scaleY;
        ctx.lineTo(x, y);
      }
    });
    ctx.lineTo((targetValue - 40) * scaleX, chartHeight);
    ctx.closePath();
    ctx.clip();

    // Create a gradient from peach color to light peach color
    const gradient = ctx.createLinearGradient(0, 0, 0, chartHeight);
    gradient.addColorStop(0, "#FFECEA");
    gradient.addColorStop(1, "#FFECEA");

    // Paint the remaining area beyond the target value with the gradient color inside the clipping region
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, chartWidth, chartHeight);

    // Draw a vertical line at the target value
    const targetX = (targetValue - 40) * scaleX;
    ctx.beginPath();
    ctx.strokeStyle = "#F48D7E"; // Set the line color to red
    ctx.lineWidth = 2;
    ctx.moveTo(targetX, 0);
    ctx.lineTo(targetX, chartHeight);
    ctx.stroke();

    // Reset the clipping region
    ctx.restore();
  }, [targetValue]);

  const mobileWidth = 250;
  const mobileHeight = 50;
  const width = window.innerWidth < 600 ? mobileWidth : 450;
  const height = window.innerWidth < 600 ? mobileHeight : 100;

  return <canvas ref={chartRef} width={width} height={height} />;
};

export default NormalDistributionChart;
