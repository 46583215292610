import React, { useEffect, useState } from "react";
import { SlInfo } from "react-icons/sl";
import { FiCornerDownRight } from "react-icons/fi";
import NormalDistributionChart from "../components/normalDistributionChart";
import BarChart from "../components/BarChart";
import { useParams } from "react-router-dom";
import { getAiReport, reportbyId } from "../services/game.service";

const HoverComponent = ({ hoverText, top, left, svg, textStyle }) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <SlInfo size={20} />

      {isHovering && (
        <div style={{ position: "absolute", top, left }} className=" z-30">
          <div className="relative">
            {svg}
            <p className={`absolute z-50  text-center ${textStyle}`}>
              {hoverText}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

const B2cReport = () => {
  const [data, setData] = useState(null);
  const [err, setErr] = useState(false);
  const [employee, setEmployee] = useState(null);
  const { id } = useParams();
  const [aiReport, setAiReport] = useState(null)

  useEffect(() => {
    reportbyId(id)
      .then((res) => {
        setData(res.data);
        console.log("data", res.data);
      })
      .catch((error) => {
        console.log(error);
        setErr(true);
      });

    getAiReport(id).then(val => {
      console.log(val.data)
      setAiReport(val.data)
    })
  }, [id]);

  if (data === null) {
    return (
      <div className="h-screen flex justify-center items-center">
        Loading...
      </div>
    );
  }

  const getGradientColor = (value) => {
    const percentage = value * 25;
    return `linear-gradient(to left, #D9D9D9 ${100 - percentage
      }%, #66BCCE ${0}%)`;
  };

  const top3SkillLabels = data.top3Skills
    ? data.top3Skills.map((item) => item.mnName)
    : [];
  const top3SkillData = data.top3Skills
    ? data.top3Skills.map((item) => item.level)
    : [];

  const bottom3SkillsLabels = data.bottom3Skills
    ? data.bottom3Skills.map((item) => item.mnName)
    : [];
  const bottom3SkillsData = data.bottom3Skills
    ? data.bottom3Skills.map((item) => item.level)
    : [];

  const chartConfig = {
    type: "bar",
    data: {
      labels: top3SkillLabels,
      datasets: [
        {
          label: "Sales",
          data: top3SkillData,
          backgroundColor: "#F18B8B",
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 5,
        },
      },
    },
  };
  const chartConfig2 = {
    type: "bar",
    data: {
      labels: bottom3SkillsLabels,
      datasets: [
        {
          label: "Оноо",
          data: bottom3SkillsData,
          backgroundColor: "#324d72",
          borderWidth: 1,
        },
      ],
    },
    options: {
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          beginAtZero: true,
          max: 5,
        },
      },
    },
  };

  const findSkill = (name) =>
    data.skillDetails.find((skill) => skill.name === name);

  const formattedCi = parseFloat(data.assPlayer.ci).toFixed(2);
  const formattedmpi = parseFloat(data.assPlayer.mpi).toFixed(2);

  return (
    <div className="w-11/12 sm:w-9/12 m-auto pt-8">
      <div>
        <div className="flex justify-end">
          <img className=" h-14" src="/blackLogo.svg" alt="logo" />
        </div>
        <div className="border-b w-full mb-8 mt-4" />

        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between">
          <div className="flex items-center gap-5">
            <p className="text-sm sm:text-lg">Овог нэр:</p>
            <p className="text-base sm:text-xl font-bold">{aiReport?.name.charAt(0).toUpperCase() + aiReport?.name.slice(1)}</p>
          </div>
          <div className="flex items-center gap-5  sm:mt-0 mt-6">
            <p className=" text-sm sm:text-lg">Цахим шуудан:</p>
            <p className="text-base sm:text-xl font-bold">{aiReport?.email}</p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mt-6">
          <div className="flex items-center gap-5">
            <p className=" text-sm sm:text-lg">Мэргэжил:</p>
            <p className="text-base sm:text-xl font-bold">{aiReport?.major}</p>
          </div>
          <div className="flex items-center gap-5  sm:mt-0 mt-6">
            <p className=" text-sm sm:text-lg">Дугаар:</p>
            <p className="text-base sm:text-xl font-bold">{aiReport?.phoneNumber}</p>
          </div>
        </div>
        <div className="mt-20 ">
          <p className="text-2xl sm:text-3xl text-center font-bold">
            Тайланг тайлж ойлгоход анхаарах зүйлс
          </p>
          <div className="mt-20">
            <p className="text-base sm:text-lg">
              Метаког Метрикс нь сэтгэл судлал болон тархи судлалын шинжлэх
              ухааны сүүлийн үеийн туршилт, судалгааны үр дүнгүүд болон тоглоомд
              суурилсан бие хүний оношилгоо хийх аргад тулгуурлан хөгжүүлэгдсэн
              систем юм.
            </p>
            <p className="text-base sm:text-lg mt-8 ">
              Метаког тоглоомуудаас 19 өөр төрлийн ур чадвар, шинж чанар
              харагдах ба тэдгээр нь 2 ерөнхий бүлэгт хуваагдаж байгаа нь нэгт,
              таны оюун ухааны үзүүлэлтүүд. Хоёрт, зан төлөвийн үзүүлэлтүүд.
            </p>
            <p className="text-base sm:text-lg mt-8">
              MPI болон CI буюу Оюун ухааны үзүүлэлт нь уламжлалт IQ үзүүлэлтийг
              орлох бөгөөд Стэнфорд Бинегийн Оюун Ухааны үзүүлэлтийг
              тоглоомуудаас гарган авч буй оноо юм.
            </p>
            <p className="text-base sm:text-lg mt-8">
              Зан төлөвийн үзүүэлэлтүүд, шинж чанар, ур чадваруудад сайн, муу
              гэсэн тал байхгүй ба “Хоёр туйлт график”-аар таны онцлог шинж
              чанаруудыг илтгэж байгаа юм.
            </p>
          </div>
        </div>
        <div>
          <p className="my-20 text-2xl sm:text-3xl text-center font-bold">
            Дэлгэрэнгүй тайлан
          </p>
          <div className="w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mb-10 mt-5">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-3 text-base top-10"}
              top={20}
              left={20}
              hoverText="Оюун ухааны чадвар нь ерөнхийлөн болон нарийвчлан дүгнэх чадвар, анхаарал, ой тогтоолт зэрэг олон чадваруудын нэгдлээр тодорхойлогддог. Матаког Метрикс тоглоомын оюун ухааны үзүүлэлтүүд нь Стэнфорд Бинегийн оюун ухааны онол, оношилгооны аргын бүтцэд үндэслэгдэн боловсруулагдсан болно."
            />
            <p className="text-xl sm:text-2xl font-bold">
              1-р түвшний оюун ухааны үзүүлэлтүүд
            </p>
          </div>
          <div>
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/inductive.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  1. {findSkill("inductive").mnName}
                </p>
              </div>
              <div className="flex items-center gap-5">
                <div className="flex items-center">
                  <div className="relative w-[200px] sm:w-[400px]">
                    <p className="absolute -bottom-5 -right-5">Өндөр</p>
                    <p className="absolute -bottom-5 -left-5">Бага</p>
                    <input
                      type="range"
                      max="5"
                      min="1"
                      value={3}
                      disabled
                      className="appearance-none w-full h-[6px]  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                      style={{
                        backgroundImage: getGradientColor(
                          findSkill("inductive").level - 1
                        ),
                        cursor: "pointer",
                      }}
                    />
                    <div className="absolute top-[18px] -mt-1 h-1 w-full">
                      <span
                        className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                        style={{ left: "2%" }}
                      ></span>
                      {[1, 2, 3, 4].map((value) => (
                        <span
                          key={value}
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("inductive").level - 1 >= value
                            ? "bg-[#66BCCE]"
                            : "bg-[#D9D9D9]"
                            }`}
                          style={{ left: `${value * 24 + 2}%` }}
                        ></span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-xs sm:text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.inductiveReasoningAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/processingSpeed.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  2. {findSkill("processingSpeed").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("processingSpeed").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("processingSpeed").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.processingSpeedAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/attention.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  3. {findSkill("attention").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("attention").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("attention").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.attentionAi}

              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/quantitative.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  4. {findSkill("quantitativeReasoing").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("quantitativeReasoing").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("quantitativeReasoing").level - 1 >=
                              value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.quantitativeReasoingAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/deductive.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  5. {findSkill("deductive").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] w-full appearance-none rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("deductive").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("deductive").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.deductiveReasoningAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/memory.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  6. {findSkill("workingMemory").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("workingMemory").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("workingMemory").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.workingMemoryAi}
              </p>
            </div>
            <div className="flex justify-center items-start mx-10 mt-20">
              <div>
                <p className="text-base sm:text-lg text-[#324D72] font-bold text-center">
                  10.000 орчим хүмүүстэй харьцуулахад таны:
                </p>
                <div className="flex items-center gap-10 my-3">
                  <p className="sm:text-base text-xs">
                    MPI оноо ( Metacog Performance Index ) - {formattedmpi}
                  </p>
                  <p className="sm:text-base text-xs">
                    Дүгнэлт: {data.ilevel.giiName} ({data.ilevel.giiLvl})
                  </p>
                </div>
                <div className="flex justify-center">
                  <NormalDistributionChart targetValue={formattedmpi} />
                </div>
                <p className="mt-1 text-xs">
                  * Та нийт тоглосон хүмүүсийн {data.mpiPercent.toFixed(2)}% аас
                  илүү үзүүлэлттэй байна.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-20">
          <div className="relative w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5 mb-20">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-4 text-base top-10"}
              top={40}
              left={40}
              hoverText="Метаког метриксийн ур чадварын үзүүлэлтүүд нь ажлын байранд
              шаардлагатай зан үйл юм. Аливаа асуудлыг шийдвэрлэхэд хүний тархи
              ямар дэс дараалалтай ажилладгийг баталсан судалгаануудын үр дүн"
            />
            <p className="text-xl ms-8 font-bold">
              2-р түвшний ур чадварын үзүүлэлтүүд
            </p>
          </div>
          <div>
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/critical-thinking.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  7. {findSkill("criticalThinking").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("criticalThinking").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("criticalThinking").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.criticalThinkingAi}
              </p>
            </div>
          </div>{" "}
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/pattern.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  8. {findSkill("patternRecognition").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("patternRecognition").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("patternRecognition").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.patternRecognitionAi}
              </p>
            </div>
          </div>{" "}
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/decision-making.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  9. {findSkill("decisionMaking").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("decisionMaking").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("decisionMaking").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.decisionMakingAi}

              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/learning.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  10. {findSkill("learningRate").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("learningRate").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("learningRate").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.learningRateAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex sm:flex-row flex-col items-center">
              <div className="py-2 flex items-center pe-10">
                <div className="w-10">
                  <img
                    src="/assets/report/planning.png"
                    className="h-[30]px w-[30px]"
                    alt="logo"
                  />
                </div>
                <p className="text-lg font-bold w-[240px] ">
                  11. {findSkill("planning").mnName}
                </p>
              </div>
              <div>
                <div className="flex items-center gap-5">
                  <div className="flex items-center">
                    <div className="relative w-[200px] sm:w-[400px]">
                      <p className="absolute -bottom-5 -right-5">Өндөр</p>
                      <p className="absolute -bottom-5 -left-5">Бага</p>
                      <input
                        type="range"
                        max="5"
                        min="1"
                        value={3}
                        disabled
                        className="h-[6px] appearance-none w-full  rounded-full outline-none focus:outline-none overflow-hidden z-30"
                        style={{
                          backgroundImage: getGradientColor(
                            findSkill("planning").level - 1
                          ),
                          cursor: "pointer",
                        }}
                      />
                      <div className="absolute top-[18px] -mt-1 h-1 w-full">
                        <span
                          className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 bg-[#66BCCE]`}
                          style={{ left: "2%" }}
                        ></span>
                        {[1, 2, 3, 4].map((value) => (
                          <span
                            key={value}
                            className={`h-4 w-4 rounded-full absolute left-0 transform -translate-x-1/2 -translate-y-1/2 ${findSkill("planning").level - 1 >= value
                              ? "bg-[#66BCCE]"
                              : "bg-[#D9D9D9]"
                              }`}
                            style={{ left: `${value * 24 + 2}%` }}
                          ></span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.planningAi}
              </p>
            </div>
          </div>
          <div className="flex justify-center items-start mx-10 mt-20">
            <div>
              <p className="text-base sm:text-lg text-[#324D72] text-center font-bold">
                10.000 орчим хүмүүстэй харьцуулахад таны:
              </p>
              <div className="flex items-center justify-between gap-5 mb-5 mt-3">
                <p className="sm:text-base text-xs">
                  СI оноо (Competency Index) - {formattedCi}{" "}
                </p>
                <p className="sm:text-base text-xs">
                  {" "}
                  Дүгнэлт: {data.ilevel.ciName} ({data.ilevel.ciLvl})
                </p>
              </div>
              <div className="flex justify-center">
                <NormalDistributionChart targetValue={formattedCi} />
              </div>
              <p className="mt-1 text-xs">
                * Та нийт тоглосон хүмүүсийн {data.ciPercent.toFixed(2)}% аас
                илүү үзүүлэлттэй байна.
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="w-full bg-[#D9D9D9] px-5 py-2 flex items-center bg-opacity-30 gap-2 mt-5">
            <HoverComponent
              svg={
                <svg
                  width="411"
                  height="225"
                  viewBox="0 0 411 225"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.24029 16.9481V16.919L4.23364 16.8908L0.343225 0.366924L24.6664 7.83639L24.7023 7.8474H24.7398H410.75V224.75H4.24029V16.9481Z"
                    fill="white"
                    stroke="#464A54"
                    strokeWidth="0.5"
                  />
                </svg>
              }
              textStyle={"ms-2 text-base top-2"}
              top={40}
              left={40}
              hoverText="Зан төлөв гэдэг нь сэтгэл судлалын шинжлэх ухаанд зан араншин (темперамент) гэсэн ойлголтоос ялгаатай бөгөөд тухайн хүний удамшил, генетикийн мэдээллээс илүүтэйгээр, өсөж төлөвших нийгэмшихүйн явцад бий болсон бодол, мэдрэмжийн төлөвшсөн хэлбэрийг хэлдэг. Метаког Метриксийн зан төлөвийн үзүүлэлтүүд нь “Таван хүчин зүйлст бие хүний асуулга” аргад тулгуурласан бөгөөд дараах 3 бүлэг зан төлөвийн 9 дэд үзүүлэлтийг тодорхойлно."
            />
            <p className="text-xl font-bold">Зан төлвийн үзүүлэлтүүд</p>
          </div>
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img src="/assets/report/curiosity.png" alt="Logo" />
                <p className="text-center text-xl font-bold ">Сониуч зан</p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Тогтвортой, сурсан дадлаа хадгалахыг эрхэмлэдэг
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("curiosity").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Таамаглаагүй өөрчлөлтөд нээлттэй ханддаг
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.curiosityAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/risk-taking.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">
                  Эрсдэл хүлээх чадвар
                </p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Аливаад болгоомжтой, няхуур ханддаг
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("riskTaking").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Хязгаарлагдмал мэдээлэлтэйгээр боломжийг ашиглах чадвартай
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.riskTakingAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/adaptability.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">
                  Дасан зохицох чадвар
                </p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Тогтвортой орчин нөхцөлд тууштай ажиллах талтай
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("adaptability").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Таамаглаагүй өөрчлөлтөд нээлттэй ханддаг
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.adaptabilityAi}
              </p>
            </div>
          </div>{" "}
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/cooperative.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">
                  Багаар ажиллах чадвар
                </p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Бие даан, ганцаараа ажиллах дуртай
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("cooperative").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Хамтарч, Багаар ажиллах дуртай
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.cooperativeAi}
              </p>
            </div>
          </div>{" "}
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/trust.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">
                  Бусдад итгэх хандлага
                </p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Амархан итгэдэггүй, сонор, хашир ханддаг
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("trusting").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Бусдад итгэл хүлээлгэдэг, эергээр ханддаг
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.trustingAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/assertive.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">
                  Өөртөө итгэлтэй, шийдэмгий байдал
                </p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Зохицомтгой, бусдын үзэл бодолд уян хатан ханддаг
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("assertiveness").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Өөрийн үзэл бодолд тууштай, зоримог, шийдэмгий
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.assertivenessAi}
              </p>
            </div>
          </div>
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/goal-setting.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">Тууштай байдал</p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Олон шинэ зүйлсийг турших дуртай
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("consistence").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Эхэлсэн зүйлээ дуусгахыг эрхэмлэдэг
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.consistenceAi}
              </p>
            </div>
          </div>{" "}
          <div className="mt-[4rem]">
            <div className="flex justify-center">
              <div className="flex items-center w-[300px] justify-center gap-2">
                <img
                  src="/assets/report/consistency.png"
                  className="w-10 h-10"
                  alt="Logo"
                />
                <p className="text-center text-xl font-bold ">
                  Зорилго төлөвлөлт
                </p>
              </div>
            </div>
            <div className="flex gap-2 justify-center sm:justify-between items-center ">
              <div className="flex items-center gap-4">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Бүтэц болон төлөвлөлгүйгээр туршиж үзэх ажиллах дуртай
                </p>
              </div>
              <div className="relative">
                <input
                  type="range"
                  max="5"
                  min="1"
                  value={findSkill("goalSetting").level}
                  disabled
                  className="custom-range w-[180px] sm:w-[380px] h-[12px] appearance-none rounded-full border border-[#ABADB5]"
                  style={{
                    backgroundImage:
                      "linear-gradient(to right, #7DC337, white, #F5BE6C)",
                  }}
                />
              </div>
              <div className="flex items-center gap-4 ">
                <p className="text-[10px] sm:text-base text-center w-full sm:w-[220px]">
                  Маш сайн төлөвлөж, түүндээ хүрэхийг зорьдог
                </p>
              </div>
            </div>
            <div className="ms-0 sm:ms-20 mt-10 flex items-center gap-2 sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
                {aiReport.goalSettingAi}

              </p>
            </div>
          </div>
          <div className="mt-10 sm:mt-20">
            <p className="text-center text-2xl font-bold pt-20">Дүгнэлт</p>
            <div className="flex flex-col sm:gap-20 sm:flex-row items-start justify-between mt-20">
              <div className="flex w-full items-center justify-center">
                <div>
                  <p className="text-xl text-center font-bold mb-5">
                    Хамгийн өндөр чадварууд
                  </p>
                  <div>
                    <BarChart chartConfig={chartConfig} />
                  </div>
                </div>
              </div>
              <div className="flex w-full mt-20 sm:mt-0 items-center justify-center">
                <div>
                  <p className="text-xl text-center font-bold mb-5">
                    Илүү хөгжүүлэх чадварууд
                  </p>
                  <div>
                    {" "}
                    <BarChart chartConfig={chartConfig2} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ms-0 sm:ms-20 my-20 sm:my-10 flex items-center gap-2  sm:gap-10">
              <FiCornerDownRight className="h-10 w-10" />
              <p className="text-xs sm:text-sm bg-[#D9D9D9] bg-opacity-30 p-4 w-4/5 rounded-2xl">
              {aiReport.consistenceAi}

              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default B2cReport;
