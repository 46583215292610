import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto"; // Import Chart.js library

const ChartComponent = ({ chartConfig }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  useEffect(() => {
    // Initialize the chart when the component mounts
    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new Chart(ctx, chartConfig);

    // Cleanup function to destroy the chart when the component unmounts
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [chartConfig]);

  return <canvas ref={chartRef} />;
};

export default ChartComponent;
