import { Box, LinearProgress, Step, StepLabel, Stepper, linearProgressClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Unity, useUnityContext } from "react-unity-webgl";
import { assessmentPlayerByLink, createMissingScores, createScore, sendCustomReport } from "../services/game.service";

import ReactPlayer from 'react-player';

const steps = [
    "Тоглоом 1",
    "Тоглоом 2",
    "Тоглоом 3",
    "Тоглоом 4",
    "Тоглоом 5",
    "Тоглоом 6",
    "Тоглоом 7",
    "Тоглоом 8",
];

const games = [
    "ARROWS",
    "SWITCH",
    "DELIVERY",
    "NOMADLIFE",
    "TEAM",
    "CARDS",
    "WATERWELL",
    "NEIGHBORS",
];

// arrow,  delivery, nomad, cards, neoghbors, water, team, switch
const order = [0, 2, 3, 5, 7, 6, 4, 1];

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor:
            theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === "light" ? "#30e83c" : "#30e880",
    },
}));

const Game = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [video, setVideo] = useState(false);
    const [show, setShow] = useState(false);
    const [step, setStep] = useState(0);
    const [assessmentPlayerId, setAssessmentPlayerId] = useState(null);
    const [playedGame, setPlayedGame] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const [play, setPlay] = useState(false)

    const { unityProvider, loadingProgression, isLoaded, sendMessage, addEventListener, removeEventListener } = useUnityContext({
        loaderUrl: "../../unity/test/unityTest.loader.js",
        dataUrl: "../../unity/test/unityTest.data",
        frameworkUrl: "../../unity/test/unityTest.framework.js",
        codeUrl: "../../unity/test/unityTest.wasm",
    });

    useEffect(() => {
        console.log("ID = ", id)
        const detectDeviceType = () => {
            setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
        };

        detectDeviceType();
        assessmentPlayerByLink({ link: id }).then(val => {
            if (val.data.playedGame > 7) {
                navigate(`/congratz`);
            }
            setPlayedGame(val.data.playedGame);
            setAssessmentPlayerId(val.data.id);
            setPlay(true)
        }).catch(error => {
            // Handle the error here
            console.error('An error occurred:', error);
            // Navigate to a specific error page or handle the error in another way
            navigate('/not-found');
        });;
    }, [])

    useEffect(() => {
        let gameScores = [];
        let st = playedGame;
        const gameOverListener = async function (result) {
            console.log("result1 = ", result);
            console.log("ST = ", st);
            gameScores = [...gameScores,
            {
                assessmentPlayerId: assessmentPlayerId,
                gameCode: games[order[st]],
                values: result,
            }];

            if (st < 7) {
                createScore({
                    assessmentPlayerId: assessmentPlayerId,
                    gameCode: games[order[st]],
                    values: result,
                })
                    .then((res) => {
                    })
                    .catch((error) => {
                        console.log("Error on creating scores")
                    });
                st = st + 1;

                sendMessage("MetaCog", "GameLang", `${order[st]},0`);
                setStep(st);
            } else {
                await createScore({
                    assessmentPlayerId: assessmentPlayerId,
                    gameCode: games[order[st]],
                    values: result,
                })
                    .then((res) => {

                    })
                    .catch((error) => {
                        console.log("Error on creating scores")
                    });
                await createMissingScores(gameScores);

                sendCustomReport(assessmentPlayerId);
                // await metacogContext.removeAllEventListeners();
                navigate(`/congratz`);
            }


            // console.log("gamescores = ", gameScores)

            // if (st === 8) {
            //     createCustomScores(gameScores).then(val => {
            //         console.log("values = ", val)
            //     })
            // }
        };

        addEventListener("GameOver1", gameOverListener);



        // Cleanup function to remove event listener when component unmounts
        return () => {
            removeEventListener("GameOver1", gameOverListener);
        };
    }, [addEventListener, sendMessage]);


    useEffect(() => {
        if (isLoaded === true) {
            sendMessage("MetaCog", "GameLang", `${order[playedGame]},0`)
            setTimeout(() => {
                setShow(true);
            }, 2000);
        }
    }, [isLoaded])

    return (
        <Fragment>
            {
                isMobile ?
                    <div className="bg-cover bg-center h-screen flex items-center justify-center" style={{ backgroundImage: `url(${"/mobile.png"})` }}>
                        {/* Please use PC */}
                    </div> :
                    <div className="wrapper flex h-screen grid grid-rows-8 bg-[#f6f7f8]">
                        <div className="row-span-1 flex justify-center items-center z-30">
                            <Box sx={{ width: "100%" }}>
                                <Stepper activeStep={step} alternativeLabel>
                                    {
                                        steps.map((label) => (
                                            <Step key={label}>
                                                <StepLabel>{label}</StepLabel>
                                            </Step>
                                        ))
                                    }
                                </Stepper>
                            </Box>
                        </div>
                        {/* <div className="unity-wrapper flex justify-center"> */}
                        {!show && (
                            <div
                                className={
                                    " top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-screen h-screen bg-[#f6f7f8] z-20 fixed"
                                }
                            >
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <div className=" text-center text-lg">
                                        {('Уншиж байна. Та түр хүлээнэ үү...')}
                                        <div className="font-bold">{loadingProgression.toFixed(2) * 100}%</div>
                                    </div>
                                    <div style={{ width: 350 }} className="mt-4">
                                        <BorderLinearProgress variant="determinate" value={loadingProgression * 100} />
                                    </div>
                                </div>
                            </div>)}
                        <div className="row-span-7 m-auto">
                            <div
                                className={`pb-14 pl-8 pr-8  ${show === false
                                    ? "bg-[#f6f7f8] bg-clip-padding pt-12"
                                    : "bg-white pt-8 shadow-xl bg-clip-padding"
                                    }  `}
                            >
                                <div className="unity-wrapper flex justify-center">

                                    <Unity
                                        className="unity-canvas"
                                        unityProvider={unityProvider}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* </div> */}
                        {video === false ? (
                            <div
                                className={
                                    " top-1/3 left-1/2 transform -translate-x-1/2 -translate-y-1/3 w-screen h-screen bg-[#f6f7f8] z-30 fixed"
                                }
                            >
                                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                                    <div className="pb-4 text-2xl">

                                        <>
                                            Та видео зааврыг үзэж дууссан бол  {" "}
                                            <button
                                                className="font-semibold"
                                                style={{ color: "#FFC5A8" }}
                                                onClick={() => {
                                                    setVideo(true);
                                                }}
                                            >
                                                ЭНД
                                            </button>
                                        </>
                                    </div>
                                    <ReactPlayer
                                        url='https://vimeo.com/836379695'
                                        controls
                                        playing={true}
                                        width='1154px'
                                        height='649px'
                                        onEnded={() => {
                                            setVideo(true);
                                        }}
                                    />

                                </div>
                            </div>
                        ) : null}
                    </div>
            }

        </Fragment>
    );
}

export default Game