import React from "react";

const PaymentDone = () => {
  return (
    <div className="min-h-screen bg-[#000] flex justify-center items-center">
      <div>
        <div className="flex items-center justify-center ">
          <svg
            className="mb-5"
            width="117"
            height="118"
            viewBox="0 0 117 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M115.643 53.7758V59.033C115.636 71.3554 111.646 83.3454 104.268 93.2148C96.8896 103.084 86.5188 110.304 74.7021 113.798C62.8853 117.292 50.2558 116.872 38.697 112.602C27.1382 108.332 17.2695 100.439 10.5627 90.1019C3.85584 79.7646 0.67027 67.5362 1.48105 55.2405C2.29182 42.9448 7.0555 31.2406 15.0616 21.8734C23.0678 12.5062 33.8874 5.97803 45.9068 3.26239C57.9263 0.546756 70.5016 1.7892 81.7572 6.80442M115.643 13.3188L58.5 70.5188L41.3572 53.3759"
              stroke="#00C48C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p className="text-center text-xl text-white">
          Төлбөр амжилттай төлөгдлөө!
        </p>
        <p className="text-white opacity-80 text-sm mt-2 text-center mb-20">
          Та өөрийн бүртгүүлсэн цахим <br /> шуудангаа шалгана у.
        </p>
      </div>
      <div className="absolute bottom-0">
        <img src="/bgSuc.png" alt="bg" />
      </div>
    </div>
  );
};

export default PaymentDone;
