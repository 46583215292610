import React from "react";
import { useNavigate } from "react-router-dom";

const PaymentErr = () => {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen bg-[#000] flex justify-center items-center">
      <div>
        <div className="flex items-center justify-center ">
          <svg
            className="mb-5"
            width="117"
            height="118"
            viewBox="0 0 117 118"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M75.6426 41.8574L41.3569 76.1431M41.3569 41.8574L75.6426 76.1431M115.643 59.0003C115.643 90.5594 90.0589 116.143 58.4998 116.143C26.9407 116.143 1.35693 90.5594 1.35693 59.0003C1.35693 27.4411 26.9407 1.85742 58.4998 1.85742C90.0589 1.85742 115.643 27.4411 115.643 59.0003Z"
              stroke="#FF647C"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p className="text-center text-xl text-white">
          Төлбөр төлөлт амжилтгүй!
        </p>
        <p className="text-white text-sm mt-2 opacity-80 text-center mb-20">
          Ахин оролдоно уу.
        </p>
        <div className="flex justify-center">
          <button
            onClick={() => {
              navigate("/payment");
            }}
            className={`text-white absolute bottom-10 z-50 bg-[#1f1f1f] w-[290px] sm:w-[235px] px-6 py-2.5 rounded-2xl `}
          >
            Үргэлжлүүлэх
          </button>
        </div>
      </div>
      <div className="absolute bottom-0">
        <img src="/bgErr.png" alt="bg" />
      </div>
    </div>
  );
};

export default PaymentErr;
