import axios from 'axios';
// import { message } from 'antd';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_HOST,
});

// Request interceptor for API calls
api.interceptors.request.use(
    async (config) => {
        const mainConfing = config;
        // const accessToken = Cookies.get('access_token');
        // if (accessToken === null) return mainConfing;
        // mainConfing.headers = {
        //   Authorization: `Bearer ${accessToken}`,
        // };
        return config;
    },
    (error) => {
        Promise.reject(error);
    },
);

// Response interceptor for API calls
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error?.response) {
            // message.error(JSON.stringify(error.response.data));
            // const originalRequest = error.config;
            if (error.response.status === 401) {
                // sessionStorage.clear();
                // localStorage.clear();
                // Cookies.remove('access_token');
                window.location.reload();
            }
            // if (error.response.status === 403 && !originalRequest._retry) {
            //   sessionStorage.clear();
            //   window.location.reload();
            //   //   originalRequest._retry = true;
            //   //   const access_token = await refreshAccessToken();
            //   //   axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            //   //   return api(originalRequest);
            // }
        } // else message.error(JSON.stringify(error));
        return Promise.reject(error);
    },
);

export default api;
