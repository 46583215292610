import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";

const Accordion = ({ id, content, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState({
    degree: false,
    country: false,
    schoolName: false,
    profession: false,
  });
  const [valueC, setValueC] = useState("");
  const [suggestionsC, setSuggestionsC] = useState([]);
  const [formData, setFormData] = useState({ ...content });
  const countries = [
    "Австрали",
    "Австри",
    "Америк",
    "Аргентин",
    "Белги",
    "Болгар",
    "Бразил",
    "Вьетнам",
    "Герман",
    "Голланд",
    "Грек",
    "Дани",
    "Египет",
    "Индонези",
    "Ирланд",
    "Испани",
    "Итали",
    "Их Британи",
    "Казакстан",
    "Канад",
    "Люксембург",
    "Малайз",
    "Монгол",
    "Мороко",
    "Норвеги",
    "Орос",
    "Польш",
    "Румын",
    "Сингапур",
    "Солонгос",
    "Тайвань",
    "Тайланд",
    "Турк",
    "Унгар",
    "Финланд",
    "Франц",
    "Хятад",
    "Чех",
    "Швед",
    "Швейцарь",
    "Шинэ Зеланд",
    "Шри Ланка",
    "Япон",
    "Энэтхэг",
    "Бусад",
  ];

  const onChange = (event) => {
    const inputValue = event.target.value;
    setValueC(inputValue);
    const filteredSuggestions = countries.filter((country) =>
      country.toLowerCase().includes(inputValue.toLowerCase())
    );
    setSuggestionsC(filteredSuggestions);
    handleInputChange("country", inputValue);
  };

  const onSuggestionClick = (suggestion) => {
    setValueC(suggestion);
    setSuggestionsC([]);
    handleInputChange("country", suggestion);
  };

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleEdit = (field) => {
    setEdit((prevEdit) => ({
      ...prevEdit,
      [field]: !prevEdit[field],
    }));
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    onUpdate({ ...content, [fieldName]: value });
  };

  return (
    <div className=" mb-4 ">
      <div className="centered">
        {console.log("content", content)}
        <div className="flex justify-between items-center">
          <p className=" text-white text-lg">{id}. Боловсрол</p>
          <button
            className="bg-[#cfcfcf] bg-opacity-5 text-white rounded-full p-2"
            onClick={toggleAccordion}
            aria-expanded={isOpen ? "true" : "false"}
            aria-controls={`accordion-content-${id}`}
          >
            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
        </div>
        {isOpen && (
          <div
            id={`accordion-content-${id}`}
            className="mt-3 "
            role="region"
            aria-labelledby={`accordion-button-${id}`}
          >
            <div>
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Боловсролын зэрэг*
                </label>
                <button
                  onClick={() => toggleEdit("degree")}
                  className="text-white"
                >
                  {!edit.degree ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.degree ? (
                <p className="text-white opacity-80">{formData.degree}</p>
              ) : (
                <select
                  className="text-white bg-inherit ps-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                  value={formData.degree}
                  onChange={(e) => handleInputChange("degree", e.target.value)}
                >
                  <option value="Мэргэшсэн">Мэргэшсэн</option>
                  <option value="Баклавар">Бакалавар</option>
                  <option value="Магистр">Магистр</option>
                  <option value="Доктор">Доктор</option>
                </select>
              )}
            </div>
            <div className="my-4">
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Суралцсан улс*
                </label>
                <button
                  onClick={() => toggleEdit("country")}
                  className="text-white"
                >
                  {!edit.country ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.country ? (
                <p className="text-white opacity-80">{formData.country}</p>
              ) : (
                <div>
                  <select
                    value={formData.country}
                    onChange={(e) =>
                      handleInputChange("country", e.target.value)
                    }
                    className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                  >
                    <option value="">Select a country...</option>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Сургуулийн нэр*
                </label>
                <button
                  onClick={() => toggleEdit("schoolName")}
                  className="text-white"
                >
                  {!edit.schoolName ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.schoolName ? (
                <p className="text-white opacity-80">{formData.schoolName}</p>
              ) : (
                <input
                  className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                  type="text"
                  placeholder=""
                  value={formData.schoolName}
                  onChange={(e) =>
                    handleInputChange("schoolName", e.target.value)
                  }
                />
              )}
            </div>
            <div className="my-4">
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Эзэмшсэн мэргэжил*
                </label>
                <button
                  onClick={() => toggleEdit("profession")}
                  className="text-white"
                >
                  {!edit.profession ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.profession ? (
                <p className="text-white opacity-80">{formData.profession}</p>
              ) : (
                <input
                  className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                  type="text"
                  placeholder=""
                  value={formData.profession}
                  onChange={(e) =>
                    handleInputChange("profession", e.target.value)
                  }
                />
              )}
            </div>

            <div>
              <div className="flex items-center justify-end">
                <input
                  type="checkbox"
                  defaultChecked={formData.currentlyStudying}
                  onChange={() =>
                    handleInputChange(
                      "currentlyStudying",
                      !formData.currentlyStudying
                    )
                  }
                />
                <p className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Одоо сурж байгаа
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
