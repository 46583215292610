import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegSave } from "react-icons/fa";

const AccordionWork = ({ id, content, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [edit, setEdit] = useState({
    companyName: false,
    companyDirection: false,
    position: false,
    workYear: false,
  });
  const services = [
    "ХАА, Байгаль экологи",
    "Уул уурхай",
    "Барилга, Үл хөдлөх хөрөнгө",
    "Үйлдвэрлэл, Дэд бүтэц",
    "Худалдаа, Борлуулалт",
    "Мэдээлэл технологи, Харилцаа холбоо",
    "Стартап",
    "Боловсрол, Шинжлэх ухаан",
    "Автомашины засвар үйлчилгээ",
    "Ресторан, Хоол үйлдвэрлэл",
    "Аялал жуулчлал, зочид буудал",
    "Захиргаа, Хүний нөөц",
    "Тээвэр, гааль, агуулах",
    "Банк, санхүү, нягтлан бодох бүртгэл",
    "Хууль, хуулийн үйлчилгээ",
    "Дизайн, Энтертайнмент, Соёл урлаг",
    "Маркетинг, PR, Менежмент",
    "Эмнэлэг, Эм хангамж",
    "Спорт, Гоо сайхан, Фитнесс",
    "Даатгал",
    "Хэвлэл мэдээлэл, Медиа",
    "Төрийн болон төрийн бус байгууллага, ОУБ",
    "Ажилчид, туслах ажилтан",
    "Харуул хамгаалалт",
    "Үйлчилгээ",
    "Бусад",
  ];
  const [formData, setFormData] = useState({ ...content });

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleEdit = (field) => {
    setEdit((prevEdit) => ({
      ...prevEdit,
      [field]: !prevEdit[field],
    }));
  };

  const handleInputChange = (fieldName, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
    onUpdate({ ...content, [fieldName]: value });
  };

  return (
    <div className="mb-4">
      {console.log("formData", formData)}
      <div className="centered">
        <div className="flex justify-between items-center">
          <p className="text-white text-lg">{id}. Ажлын туршлага</p>
          <button
            className="bg-[#cfcfcf] bg-opacity-5 text-white rounded-full p-2"
            onClick={toggleAccordion}
            aria-expanded={isOpen ? "true" : "false"}
            aria-controls={`accordion-content-${id}`}
          >
            {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </button>
        </div>
        {isOpen && (
          <div
            id={`accordion-content-${id}`}
            className="mt-3 "
            role="region"
            aria-labelledby={`accordion-button-${id}`}
          >
            <div className="my-4">
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Байгууллагын нэр*
                </label>
                <button
                  onClick={() => toggleEdit("companyName")}
                  className="text-white"
                >
                  {!edit.companyName ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.companyName ? (
                <p className="text-white opacity-80">{formData.companyName}</p>
              ) : (
                <input
                  className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                  type="text"
                  placeholder="Баклавар"
                  value={formData.companyName}
                  onChange={(e) =>
                    handleInputChange("companyName", e.target.value)
                  }
                />
              )}
            </div>
            <div className="">
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Байгууллагын салбар чиглэл*
                </label>
                <button
                  onClick={() => toggleEdit("companyDirection")}
                  className="text-white"
                >
                  {!edit.companyDirection ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.companyDirection ? (
                <p className="text-white opacity-80">
                  {formData.companyDirection}
                </p>
              ) : (
                <select
                  value={formData.companyDirection}
                  onChange={(e) =>
                    handleInputChange("companyDirection", e.target.value)
                  }
                  className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                >
                  <option disabled value="">
                    Select a service...
                  </option>
                  {services.map((service, index) => (
                    <option key={index} value={service}>
                      {service}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="my-4">
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Албан тушаал*
                </label>
                <button
                  onClick={() => toggleEdit("position")}
                  className="text-white"
                >
                  {!edit.position ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.position ? (
                <p className="text-white opacity-80">{formData.position}</p>
              ) : (
                <input
                  className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                  type="text"
                  placeholder="Баклавар"
                  value={formData.position}
                  onChange={(e) =>
                    handleInputChange("position", e.target.value)
                  }
                />
              )}
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Ажилласан хугацаа
                </label>
                <button
                  onClick={() => {
                    toggleEdit("workYear");
                  }}
                  className="text-white"
                >
                  {!edit.workYear ? <MdOutlineEdit /> : <FaRegSave />}
                </button>
              </div>
              {!edit.workYear ? (
                <div>
                  {formData.workYear === 6 ? (
                    <p className="text-sm text-white opacity-80">
                      6 сар хүртэлх
                    </p>
                  ) : formData.workYear === 11 ? (
                    <p className="text-sm text-white opacity-80">
                      1 жил хүртэлх
                    </p>
                  ) : formData.workYear === 21 ? (
                    <p className="text-sm text-white opacity-80">
                      20оос дээш жил
                    </p>
                  ) : (
                    <p className="text-sm text-white opacity-80">
                      {Number(formData.workYear) / 12} жил
                    </p>
                  )}
                </div>
              ) : (
                <select
                  onChange={(e) => {
                    handleInputChange("workYear", parseInt(e.target.value, 10));
                  }}
                  className="bg-inherit text-white opacity-80 px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                >
                  <option value="" disabled selected>
                    Сонгох
                  </option>
                  <option value="6">6 сар хүртэлх</option>
                  <option value="11">1 жил хүртэлх</option>
                  {Array.from({ length: 20 }, (_, index) => (
                    <option key={index + 1} value={(index + 1) * 12}>
                      {index + 1} жил
                    </option>
                  ))}
                  <option value="21">20 оос дээш жил</option>
                </select>
              )}
            </div>
            <div>
              <div className="flex items-center justify-end">
                <input
                  type="checkbox"
                  defaultChecked={formData.currentlyWorking}
                  onChange={() =>
                    handleInputChange(
                      "currentlyWorking",
                      !formData.currentlyWorking
                    )
                  }
                />
                <p className="text-[#cfcfcf] text-xs ms-1 mb-1 block">
                  Одоо ажиллаж байгаа
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccordionWork;
