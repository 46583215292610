import api from "../helper/request";

const createMissingScores = (obj) => api.post("/gameScore/missingScores", obj);
const createScore = (obj) => api.post("/gameScore", obj);
const assessmentPlayerByLink = (obj) => api.post(`/assessment/find`, obj);
const reportbyId = (id) => api.get(`/gameScore/custom/report/${id}`);
const getAiReport = (id) => api.get(`/gameScore/ai/report/${id}`);
const sendCustomReport = (id) => api.get(`/assessment/custom/report/${id}`)

export { createScore, createMissingScores, assessmentPlayerByLink, reportbyId, getAiReport, sendCustomReport };
