import React, { useEffect, useRef, useState } from "react";
import { FaArrowRight, FaSpinner } from "react-icons/fa";
import { GoPlus } from "react-icons/go";
import Accordion from "../components/Accordion";
import AccordionWork from "../components/AccordionWork";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LuMinus } from "react-icons/lu";
import { IoChevronBack } from "react-icons/io5";
import { createPayment } from "../services/payment.service";

const services = [
  "ХАА, Байгаль экологи",
  "Уул уурхай",
  "Барилга, Үл хөдлөх хөрөнгө",
  "Үйлдвэрлэл, Дэд бүтэц",
  "Худалдаа, Борлуулалт",
  "Мэдээлэл технологи, Харилцаа холбоо",
  "Стартап",
  "Боловсрол, Шинжлэх ухаан",
  "Автомашины засвар үйлчилгээ",
  "Ресторан, Хоол үйлдвэрлэл",
  "Аялал жуулчлал, зочид буудал",
  "Захиргаа, Хүний нөөц",
  "Тээвэр, гааль, агуулах",
  "Банк, санхүү, нягтлан бодох бүртгэл",
  "Хууль, хуулийн үйлчилгээ",
  "Дизайн, Энтертайнмент, Соёл урлаг",
  "Маркетинг, PR, Менежмент",
  "Эмнэлэг, Эм хангамж",
  "Спорт, Гоо сайхан, Фитнесс",
  "Даатгал",
  "Хэвлэл мэдээлэл, Медиа",
  "Төрийн болон төрийн бус байгууллага, ОУБ",
  "Ажилчид, туслах ажилтан",
  "Харуул хамгаалалт",
  "Үйлчилгээ",
  "Бусад",
];

const countries = [
  "Монгол",
  "Орос",
  "Хятад",
  "Япон",
  "Солонгос",
  "Австрали",
  "Австри",
  "Америк",
  "Аргентин",
  "Белги",
  "Болгар",
  "Бразил",
  "Вьетнам",
  "Герман",
  "Голланд",
  "Грек",
  "Дани",
  "Египет",
  "Индонези",
  "Ирланд",
  "Испани",
  "Итали",
  "Их Британи",
  "Казакстан",
  "Канад",
  "Люксембург",
  "Малайз",
  "Мороко",
  "Норвеги",
  "Польш",
  "Сингапур",
  "Тайвань",
  "Тайланд",
  "Турк",
  "Унгар",
  "Финланд",
  "Франц",
  "Чех",
  "Швед",
  "Швейцарь",
  "Шинэ Зеланд",
  "Шри Ланка",
  "Энэтхэг",
  "Румын",
  "Бусад",
];

const Resume = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [hideTopSection, setHideTopSection] = useState(false);
  const [accordionData, setAccordionData] = useState([]);
  const [accordionData2, setAccordionData2] = useState([]);
  const [step, setStep] = useState(1);
  const [isChecked, setIsChecked] = useState(false);
  const [noWorkExp, setNoWorkExp] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const popUpRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [resumeFormData, setResumeFormData] = useState({
    id: 1,
    degree: "",
    country: "",
    schoolName: "",
    profession: "",
    currentlyStudying: false,
  });
  const [workExp, setWorkExp] = useState({
    companyId: 1,
    companyName: "",
    companyDirection: "",
    position: "",
    workYear: 0,
    currentlyWorking: false,
  });

  useEffect(() => {
    if (location == null || location.state === null) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target)) {
        setPopUp(false);
      }
    };

    if (popUp) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popUp]);

  const addMore = () => {
    const id = accordionData.length + 1;
    setAccordionData((prevData) => [...prevData, { id, ...resumeFormData }]);
    setResumeFormData({
      degree: "",
      country: "",
      schoolName: "",
      profession: "",
      currentlyStudying: false,
    });
  };

  const addMoreWorkExp = () => {
    const id = accordionData2.length + 1;
    const newAccordionItem = { id, ...workExp };
    setAccordionData2((prevData) => [...prevData, newAccordionItem]);
    setWorkExp({
      companyId: id + 1,
      companyName: "",
      companyDirection: "",
      position: "",
      workYear: 0,
      currentlyWorking: false,
    });
  };

  const deleteWorkExp = (index) => {
    const updatedData = [...accordionData2];
    updatedData.splice(index, 1);
    setAccordionData2(updatedData);
  };

  const deleteLastAccordion = () => {
    if (accordionData.length > 0) {
      const updatedData = [...accordionData];
      updatedData.pop(); // Remove the last item
      setAccordionData(updatedData);
    }
  };

  const handleAccordionDataChange = (updatedData) => {
    setAccordionData(updatedData);
  };

  const handleInputChange = (fieldName, value) => {
    setResumeFormData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const submitRes = () => {
    if (accordionData.length > 0) {
      const accordionHasData = accordionData.some((item) => {
        return Object.values(item).some((value) => {
          if (value === "") {
            toast.error("Бүх талбарыг бөглөнө үү.");
            return true;
          }
          return false;
        });
      });

      if (!accordionHasData) {
        setStep(2);
      }
    } else {
      const resumeFormDataHasEmptyField = Object.values(resumeFormData).some(
        (value) => value === ""
      );

      if (resumeFormDataHasEmptyField) {
        toast.error("Бүх талбарыг бөглөнө үү.");
      } else {
        setStep(2);
      }
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    console.log("STATE = ", location.state);
    console.log("noWorkExp = ", noWorkExp);
    console.log("Resume = ", resumeFormData);
    console.log("List Resume = ", accordionData);
    console.log("Work = ", workExp);
    console.log("List Work exp = ", accordionData2);

    let isWork = Object.values(workExp).every((value) => value !== "");

    let experience = accordionData2;
    if (isWork) {
      experience = [...accordionData2, workExp];
    }

    let isRes = Object.values(resumeFormData).every((value) => value !== "");

    let resumes = accordionData;

    if (isRes) {
      resumes = [...accordionData, resumeFormData];
    }

    let obj = {
      player: location.state.formData,
      playerEducations: resumes,
      playerExperiences: experience,
    };

    console.log("Obj = ", obj);

    if (noWorkExp || resumeFormData.currentlyStudying) {
      await createPayment(obj).then((val) => {
        console.log("val = ", val);

        navigate("/payment", {
          state: {
            qpay: val.data,
            isPromo: location.state.formData.isPromo,
          },
        });
      });
    } else {
      if (accordionData2.length === 0) {
        const isWorkExpEmpty = Object.values(workExp).some(
          (value) => value === ""
        );

        if (isWorkExpEmpty) {
          toast.error("Ажлын туршлагын бүх талбарыг бөглөнө үү.");
          return;
        } else {
          await createPayment(obj).then((val) => {
            console.log("val1 = ", val);
            navigate("/payment", {
              state: {
                qpay: val.data,
                isPromo: location.state.formData.isPromo,
              },
            });
          });
          return;
        }
      } else {
        const isAccordionDataEmpty = accordionData2.some((item) =>
          Object.values(item).some((value) => value === "")
        );
        console.log("accordionData2", accordionData2);
        if (isAccordionDataEmpty) {
          console.log("accordiondataempty");
          toast.error("Ажлын туршлагын бүх талбарыг бөглөнө үү.");
          return;
        }
      }

      const isWorkExpFilled = Object.values(workExp).every(
        (value) => value !== ""
      );
      const isAccordionDataFilled = accordionData2.every((item) =>
        Object.values(item).every((value) => value !== "")
      );

      if (isWorkExpFilled && isAccordionDataFilled) {
        console.log(" ALLLLLL = ");
        await createPayment(obj).then((val) => {
          console.log("val2 = ", val);
          navigate("/payment", {
            state: {
              qpay: val.data,
              isPromo: location.state.formData.isPromo,
            },
          });
        });
        return;
      }
    }
    await setLoading(false);
  };
  return (
    <div className="relative min-h-screen bg-[#0D0D0D] pb-10 pt-10  ">
      <div
        className={`bg-[#171717] w-[360px] m-auto  rounded-xl p-5 resume-section pt-10 flex-col justify-center items-center${
          hideTopSection ? "fade-out" : ""
        } ${hideTopSection ? "hidden" : ""}`}
      >
        <div className="flex justify-center">
          <p className="text-center text-[#cfcfcf] text-base px-3  ">
            Тоглоомын үр дүн дээр хиймэл оюун ухаанд суурилсан модель ажилладаг
            бөгөөд та өөрийн анкетын мэдээллийг оруулснаар зөвхөн таньд
            зориулсан зөвлөмжүүдийг авах боломжтой
          </p>
        </div>
        <div
          className={`flex justify-center my-4 transition-all duration-300 ${
            hideTopSection ? "mt-0" : "mt-5"
          }`}
        >
          <img className="w-[319px] rounded-xl" src="./cv.jpeg" alt="cv" />
        </div>
        <div className="mt-10 flex justify-center">
          <input
            type="checkbox"
            id="myCheckbox"
            defaultChecked={isChecked}
            onClick={() => {
              setIsChecked(!isChecked);
            }}
            className="ms-4 mt-2 h-6 w-6 hidden"
          />
          <div className="flex items-start my-4">
            <label
              htmlFor="myCheckbox"
              className={`cursor-pointer rounded-md border border-gray-400 w-8 h-6 flex items-center justify-center mr-2 transition-colors ${
                isChecked ? "bg-green-500 bg-opacity-80" : "bg-white"
              }`}
            >
              {isChecked && (
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                    rx="3.5"
                    fill="#E6FFEF"
                  />
                  <rect
                    x="0.5"
                    y="0.5"
                    width="17"
                    height="17"
                    rx="3.5"
                    stroke="#C9FFAF"
                  />
                  <path
                    d="M7.13046 11.2605L4.69796 8.82803L3.86963 9.65053L7.13046 12.9114L14.1305 5.91137L13.308 5.08887L7.13046 11.2605Z"
                    fill="#74FF33"
                  />
                </svg>
              )}
            </label>
            <p
              onClick={() => setPopUp(true)}
              className="text-white  leading-5 underline opacity-80 cursor-pointer"
            >
              Үйлчилгээний нөхцөл болон нууцлалын бодлого
            </p>
          </div>
        </div>
        <div
          className={`flex justify-center ${isChecked ? "block" : "hidden"}`}
        >
          <button
            onClick={() => {
              setHideTopSection(true);
            }}
            className="text-white text-sm bg-[#020202] w-[280px] px-2 py-2 rounded-2xl flex items-center gap-2 justify-center "
          >
            Үргэлжлүүлэх
            <FaArrowRight />
          </button>
        </div>
      </div>
      {popUp && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div
            ref={popUpRef}
            className="bg-[#171717] p-6 rounded-lg max-h-[80vh] max-w-[50vh] overflow-y-auto"
          >
            <p className="text-lg text-white font-semibold mb-4">
              Метакогт тавтай морилно уу!
            </p>
            <div className="text-sm text-white ">
              <p>
                Үйлчилгээний нөхцөл: <br /> <br /> Метаког платформд
                бүртгүүлснээр та манай үйлчилгээний нөхцөл ба нууцлалын бодлогыг
                зөвшөөрч байгаа тул уншиж танилцана уу. <br /> <br />{" "}
                Үйлчилгээний тодорхойлолт: <br /> <br /> Метаког нь сэтгэл зүй
                болон тархи судлалын онолуудад суурилсан тоглоомыг санал
                болгодог. Төлбөр төлсний дараа та манай тоглоомд хандах хувийн
                холбоосыг хүлээн авах болно. Дуусгасны дараа, манай платформ нь
                таны зөөлөн ур чадварыг дүгнэсэн тайлан болон AI-д суурилсан
                зөвлөмжийг үүсгэн илгээх болно. <br /> <br /> Хувийн нууц ба
                өгөгдлийн хамгаалалт:
                <br /> <br />
              </p>

              <ol className="list-disc ms-4">
                <li>
                  Өгөгдөл цуглуулах: Бид үйлчилгээг сайжруулах болон хувийн
                  туршлагыг хэрэглэгчиддээ санал болгох зорилгоор бүртгэл үед
                  хувийн мэдээллийг цуглуулдаг.
                </li>
                <li className="my-5">
                  Өгөгдлийг ашиглах: Таны мэдээллийг тайлан болон зөвлөмж бий
                  болгоход ашиглан. Бид таны хувийн мэдээллийг таны
                  зөвшөөрөлгүйгээр гуравдагч талуудтай хуваалцахгүй.
                </li>
                <li>
                  Өгөгдлийг ашиглах: Бид таны хувийн мэдээллийг
                  хуваалцахгүйгээр, таны зөөлөн ур чадвар ба сонирхлын дагуу
                  гуравдагч этгээдийн ажлын саналуудаас танд хүргүүлж болзошгүй.
                </li>
              </ol>
              <br />
              <p> Хэрэглэгчийн Үүрэг:</p>
              <br />
              <ol className="list-disc ms-4">
                <li> Нэвтрэх мэдээллээ үнэн бодитоор оруулах.</li>
                <li className="my-5">
                  Үйлчилгээг хувийн болон ашиг сонирхлын бус зорилгоор ашигла.
                </li>
                <li>
                  Тайлан нь зөвхөн танд зориулагдсан бөгөөд гуравдагч итгээдэд
                  хуваалцахгүй байх.
                </li>
              </ol>
              <br />
              <p> Метаког тоглоомууд:</p>
              <br />
              <p>
                Бүртгүүлсний дараа та сайт дээр тархи судлалд суурилсан онлайн
                тоглоомуудыг ("Тоглоом") тоглох боломжтой болно. Та тоглоом
                бүрийг тоглох үед “Метаког” нь таны Тоглоомын явцын мэдээллийг
                цуглуулж, хадгалах эрхтэй гэдгийг та зөвшөөрч байна. "Тоглоомын
                мэдээлэл" гэдэг нь тоглоом тоглох явцад таны гаргасан шийдвэр,
                үйлдлээс цуглуулсан өгөгдөл юм, үүнд мөн таны тоглоомтой
                харилцах, хариу үйлдлийн талаарх мета өгөгдөл багтана.
              </p>
              <br />
              <p>Та доорх мэдээлэлтэй танилцаад хүлээн зөвшөөрч байна:</p>
              <br />
              <ol className="list-disc  ms-4">
                <li>
                  Сайтын бүртгэлийн маягтын дагуу өөрийнхөө тухай үнэн зөв
                  одоогийн, бүрэн мэдээллийг өгөх;{" "}
                </li>
                <li className="my-5">
                  Профайл мэдээллээ оруулахдаа жинхэнэ нэр, имэйл хаягаа
                  ашиглана уу;{" "}
                </li>
                <li>
                  Өөрийн ашиглах эрхээ гуравдагч этгээдэд шилжүүлэхгүй, дахин
                  худалдахгүй байх;{" "}
                </li>
              </ol>
              <br />
              <p> Үүний дагуу та дараахь зүйлийг хийхийг зөвшөөрч байна.</p>
              <br />

              <ol className="list-disc  ms-4">
                <li className="mb-5">
                  {" "}
                  Тоглоомыг нухацтай авч, тоглоомыг анхааралтай тоглох;{" "}
                </li>
                <li className="mb-5">
                  Тоглоом бүрийг эхлүүлэхийн өмнө зааврыг анхааралтай унших;
                </li>
                <li className="mb-5">
                  {" "}
                  Тоглоомыг хагаст нь зогсоож, эсвэл тодорхой нэг Тоглоомыг
                  давтахын тулд тоглоомыг зориудаар дуусгахгүй;
                </li>
                <li className="mb-5">
                  {" "}
                  Анхаарал төвлөрөх орчин бүрдүүлж өөрийнхөөрөө шийдвэр гарган
                  тоглох.
                </li>
              </ol>
              <p>Өмчлөлийн эрх</p>
              <br />
              <p>
                Метаког-оос санал болгосон бүх агуулга, тоглоомын дизайн болон
                тайлан нь бид эсвэл манай эрх зүйн эзэмшигчидийн өмч юм. Эдгээр
                агуулгыг энэхүү нөхцөлд заасан зөвшөөрөлгүйгээр ашиглахыг
                хориглоно.
              </p>
              <br />
              <p>Бусад: </p>
              <br />
              <p>
                Бид энэхүү нөхцөл болон манай үйлчилгээг аливаа цагт өөрчлөх
                эрхтэй. "Метаког" нь энэхүү Үйлчилгээний нөхцөлд нэмэлт өөрчлөлт
                оруулах бүрт Хэрэглэгчидэд мэдэгдэх үүргийг хүлээнэ.
              </p>
              <br />
              <p>
                Үйлчилгээтэй холбоотой санал, гомдол, асуудал, маргааныг талууд
                эв зүйгээр харилцан зөвшилцөх замаар шийдвэрлэнэ. Хэрэв
                шийдвэрлэх боломжгүй бол Монгол Улсын хууль, тогтоомжид заасны
                дагуу шийдвэрлүүлнэ.{" "}
              </p>
              <br />
              <p>Бидэнтэй холбогдох:</p>
              <br />
              <p>
                Хэрэв танд сайттай холбоотой асуулт, гомдол байвал бидэнтэй
                hello@metacog.mn цахим хаягаар эсвэл холбогдоно уу.
              </p>
            </div>
            <div className="flex justify-between items-center mt-5">
              <button
                onClick={() => {
                  setPopUp(false);
                }}
                className="p-2 bg-[#020202] text-white opacity-80 underline rounded-xl me-4"
              >
                <IoChevronBack className="h-5 w-5" />
              </button>
              <button
                onClick={() => {
                  setHideTopSection(true);
                  setPopUp(false);
                }}
                className="text-white text-sm bg-[#020202] w-[280px] px-2 py-2 rounded-2xl flex items-center gap-2 justify-center "
              >
                Зөвшөөрөх
                <FaArrowRight />
              </button>
            </div>
          </div>
        </div>
      )}
      {hideTopSection && (
        <div
          className={`bg-[#0D0D0D] resume-section ${
            hideTopSection ? "fade-in" : "fade-out"
          }`}
        >
          {step === 1 ? (
            <div className="w-[350px] m-auto">
              <div>
                <button
                  onClick={() => {
                    setHideTopSection(false);
                  }}
                  className="rounded-full p-2 bg-[#1f1f1f] mb-4 text-white"
                >
                  <IoChevronBack />
                </button>
              </div>
              {accordionData.length === 0 ? (
                <div className="flex items-center  justify-center">
                  <p className="text-white text-lg  text-start ">
                    1. Боловсрол
                  </p>
                </div>
              ) : (
                <div>
                  <div>
                    {accordionData.map((item, index) => (
                      <Accordion
                        key={index}
                        id={index + 1}
                        content={item}
                        onUpdate={(updatedContent) => {
                          const updatedData = [...accordionData];
                          updatedData[index] = updatedContent;
                          handleAccordionDataChange(updatedData);
                        }}
                      />
                    ))}

                    <div className="w-full flex items-center justify-between">
                      <p className="text-white text-lg">
                        {Number(accordionData.length) + 1}. Боловсрол
                      </p>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={deleteLastAccordion}
                          className="flex items-center bg-[#1f1f1f] text-white rounded-full p-2 mt-4 gap-2"
                        >
                          <LuMinus />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div>
                <div className="mt-4  ">
                  <form>
                    <div>
                      <label className="text-[#cfcfcf] ms-1 mb-1 block">
                        Боловсролын зэрэг*
                      </label>
                      <select
                        className={`text-white bg-inherit ps-4 py-2 border border-[#4f4f4f] w-full rounded-xl appearance-none`}
                        value={resumeFormData.degree}
                        onChange={(e) =>
                          handleInputChange("degree", e.target.value)
                        }
                      >
                        <option
                          disabled
                          value=""
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Сонгох
                        </option>
                        <option
                          value="Бүрэн дунд"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Бүрэн дунд
                        </option>
                        <option
                          value="Мэргэшсэн"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Мэргэшсэн
                        </option>
                        <option
                          value="Баклавар"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Бакалавр
                        </option>
                        <option
                          value="Магистр"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Магистр
                        </option>
                        <option
                          value="Доктор"
                          style={{ backgroundColor: "black", color: "white" }}
                        >
                          Доктор
                        </option>
                      </select>
                    </div>

                    <div className="my-4">
                      <label className="text-[#cfcfcf] ms-1 mb-1 block">
                        Суралцсан улс*
                      </label>

                      <select
                        value={resumeFormData.country}
                        onChange={(e) =>
                          handleInputChange("country", e.target.value)
                        }
                        className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                      >
                        <option
                          style={{ backgroundColor: "black", color: "white" }}
                          value=""
                        >
                          Сонгох
                        </option>
                        {countries.map((country, index) => (
                          <option
                            style={{ backgroundColor: "black", color: "white" }}
                            key={index}
                            value={country}
                          >
                            {country}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <label className="text-[#cfcfcf] ms-1 mb-1 block">
                        Сургуулийн нэр*
                      </label>
                      <input
                        className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                        type="text"
                        placeholder=""
                        value={resumeFormData.schoolName}
                        onChange={(e) =>
                          handleInputChange("schoolName", e.target.value)
                        }
                      />
                    </div>
                    <div className="my-4">
                      <label className="text-[#cfcfcf] ms-1 mb-1 block">
                        Эзэмшсэн мэргэжил*
                      </label>
                      <input
                        className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                        type="text"
                        placeholder="Нягтлан"
                        value={resumeFormData.profession}
                        onChange={(e) =>
                          handleInputChange("profession", e.target.value)
                        }
                      />
                    </div>
                    <div className="mt-4 flex items-center justify-end">
                      <input
                        className="h-4 w-4"
                        type="checkbox"
                        checked={resumeFormData.currentlyStudying}
                        onChange={(e) =>
                          handleInputChange(
                            "currentlyStudying",
                            e.target.checked
                          )
                        }
                      />
                      <p className="text-[#cfcfcf] ms-2 underline">
                        Одоо сурж байгаа
                      </p>
                    </div>
                  </form>
                </div>
              </div>

              <div className="flex justify-center mt-5 gap-4">
                <button
                  onClick={addMore}
                  className="bg-[#1f1f1f] text-white flex items-center rounded-xl p-3 gap-2 text-xs"
                >
                  Боловсрол нэмэх
                  <GoPlus className="w-4 h-4" />
                </button>

                <button
                  onClick={submitRes}
                  className="text-white bg-[#1f1f1f]  p-3 rounded-xl flex items-center gap-2  text-xs"
                >
                  Ажлын туршлага нэмэх
                  <FaArrowRight className="w-2" />
                </button>
              </div>
            </div>
          ) : step === 2 ? (
            <div className="w-[350px] m-auto">
              <div>
                <button
                  onClick={() => {
                    setStep(1);
                  }}
                  className="rounded-full p-2 bg-[#1f1f1f] mb-4 text-white"
                >
                  <IoChevronBack />
                </button>
              </div>
              {accordionData2.length === 0 ? (
                <div className="flex items-center justify-center">
                  <p className="text-white  text-start text-lg">
                    1. Ажлын туршлага
                  </p>
                </div>
              ) : (
                <div>
                  <div className="">
                    {accordionData2.map((item, index) => (
                      <div key={index}>
                        {/* Render the work experience item */}
                        <AccordionWork
                          id={index + 1}
                          content={item}
                          onUpdate={(updatedContent) => {
                            const updatedData = [...accordionData2];
                            updatedData[index] = updatedContent;
                            setAccordionData2(updatedData);
                          }}
                        />
                      </div>
                    ))}

                    <div className="w-full flex items-center justify-between">
                      <p className="text-white text-lg ">
                        {Number(accordionData2.length) + 1}. Ажлын туршлага
                      </p>
                      <div className="flex items-center gap-2">
                        <button
                          onClick={deleteWorkExp}
                          className="flex items-center bg-[#1f1f1f] text-white rounded-full p-2  gap-2"
                        >
                          <LuMinus />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`flex items-center justify-center mt-2 mb-4 gap-2 ${
                  accordionData2.length !== 0 ? "hidden" : ""
                } ${
                  Object.values(workExp).some((value) => value.length > 0)
                    ? "hidden"
                    : ""
                }`}
              >
                <div className="flex ">
                  <div className="flex justify-start ">
                    <input
                      value={noWorkExp}
                      type="checkbox"
                      onChange={() => {
                        setNoWorkExp(!noWorkExp);
                      }}
                    />
                    <p className="text-[#fff] text-start ms-2  opacity-40 underline text-sm">
                      Одоогоор ажлын туршлага байхгүй
                    </p>
                  </div>
                </div>
              </div>

              {!noWorkExp && (
                <div className="w-full flex justify-center">
                  <div className=" ">
                    <form>
                      <div>
                        <label className="text-[#cfcfcf] ms-1 mb-1 block">
                          Байгууллагын нэр*
                        </label>
                        <input
                          className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                          type="text"
                          placeholder=""
                          value={workExp.companyName}
                          onChange={(e) =>
                            setWorkExp({
                              ...workExp,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="my-4">
                        <label className="text-[#cfcfcf] ms-1 mb-1 block">
                          Байгууллагын салбар чиглэл*
                        </label>

                        <select
                          value={workExp.companyDirection}
                          onChange={(e) =>
                            setWorkExp({
                              ...workExp,
                              companyDirection: e.target.value,
                            })
                          }
                          className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                        >
                          <option
                            style={{ backgroundColor: "black", color: "white" }}
                            disabled
                            value=""
                          >
                            Сонгох
                          </option>
                          {services.map((service, index) => (
                            <option
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                              key={index}
                              value={service}
                            >
                              {service}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <label className="text-[#cfcfcf] ms-1 mb-1 block">
                          Албан тушаал*
                        </label>
                        <input
                          className="text-white bg-inherit px-4 py-2 border border-[#4f4f4f] w-full rounded-xl"
                          type="text"
                          placeholder="Маркетингийн менежер"
                          value={workExp.position}
                          onChange={(e) =>
                            setWorkExp({ ...workExp, position: e.target.value })
                          }
                        />
                      </div>

                      {console.log("workExp", workExp)}
                      <div className="mt-4">
                        <label className="text-[#cfcfcf] ms-1 mb-1 block">
                          Ажилласан хугацаа
                        </label>
                        <select
                          onChange={(e) =>
                            setWorkExp({
                              ...workExp,
                              workYear: parseInt(e.target.value, 10),
                            })
                          }
                          className="bg-inherit px-4 py-2 border text-white border-[#4f4f4f] w-full rounded-xl"
                        >
                          <option
                            style={{ backgroundColor: "black", color: "white" }}
                            disabled
                            selected
                          >
                            Сонгох
                          </option>
                          <option
                            style={{ backgroundColor: "black", color: "white" }}
                            value="6"
                          >
                            6 сар хүртэлх
                          </option>
                          <option
                            style={{ backgroundColor: "black", color: "white" }}
                            value="11"
                          >
                            1 жил хүртэлх
                          </option>
                          {Array.from({ length: 20 }, (_, index) => (
                            <option
                              style={{
                                backgroundColor: "black",
                                color: "white",
                              }}
                              key={index + 1}
                              value={(index + 1) * 12}
                            >
                              {index + 1} жил
                            </option>
                          ))}
                          <option
                            style={{ backgroundColor: "black", color: "white" }}
                            value="21"
                          >
                            20 оос дээш жил
                          </option>
                        </select>
                      </div>
                      <div className="mt-4 mb-10 flex items-center justify-end">
                        <input
                          className="h-4 w-4"
                          type="checkbox"
                          checked={workExp.currentlyWorking}
                          onChange={(e) =>
                            setWorkExp({
                              ...workExp,
                              currentlyWorking: e.target.checked,
                            })
                          }
                        />
                        <p className="text-[#cfcfcf] ms-2 underline">
                          Одоо ажиллаж байгаа
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              )}
              <div className="flex justify-center items-center gap-5">
                <button
                  onClick={addMoreWorkExp}
                  className="text-white bg-[#1f1f1f]  p-3 rounded-xl flex items-center gap-2  text-xs"
                >
                  Ажлын туршлага нэмэх
                  <GoPlus />
                </button>
                {
                  <button
                    onClick={onSubmit}
                    className="text-white bg-[#1f1f1f] p-3 rounded-xl flex items-center gap-2 text-xs"
                    disabled={loading}
                  >
                    {loading ? (
                      <div className="animate-spin h-5 w-5 border-4 border-t-transparent border-white rounded-full"></div>
                    ) : (
                      <>
                        <span>Төлбөр төлөх</span>
                        <FaArrowRight />
                      </>
                    )}
                  </button>
                }
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default Resume;
