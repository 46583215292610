import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Signup from "./page/Signup";
import Resume from "./page/Resume";
import Payment from "./page/Payment";
import Mainpage from "./page/Mainpage";
import PaymentErr from "./components/PaymentErr";
import PaymentDone from "./components/PaymentDone";
import Game from "./page/GamePage";
import B2cReport from "./page/B2cReport";
import SuccessLoader from "./page/SuccessLoader";
import NotFound from "./page/NotFound";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Mainpage />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/report/:id" element={<B2cReport />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/payment/error" element={<PaymentErr />} />
          <Route path="/payment/success" element={<PaymentDone />} />
          <Route exact path="/game/:id" element={<Game />} />
          <Route path="/congratz" element={<SuccessLoader />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
